// import { isMobile } from '@/utils'

export default {
  address: '接待會館：台北市中正區齊東街70巷17號旁',
  // isMobile ? '接待中心：<br />106台北市大安區基隆路三段20-1號' : '接待中心：106台北市大安區基隆路三段20-1號',
  googleSrc:
    'https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d903.6848483301465!2d121.52775000000001!3d25.0429167!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjXCsDAyJzM0LjUiTiAxMjHCsDMxJzM5LjkiRQ!5e0!3m2!1szh-TW!2stw!4v1688722830377!5m2!1szh-TW!2stw',
  googleLink: 'https://goo.gl/maps/2RydbESHCv8s6c3GA',
  phone: '(02)2391-5577',
  fbLink:
    'https://www.facebook.com/中正H25-102637598419351/',
  fbMessage: 'https://m.me/102637598419351/',
  caseName: '中正H25',
  houseInfos: [
    ['投資興建', '力天開發股份有限公司'],
    ['建築設計', '朱午潮建築師'],
    ['接待中心', '台北市中正區齊東街70巷17號旁'],
    ['建照號碼', '109建字第0215號'],
    ['企劃銷售', '力天開發股份有限公司'],
    ['公設比例', '37%'],
    ['基地面積', '57坪'],
    ['格局規劃', '1-2房17坪、3房35坪'],
    ['樓層規劃', '地上7樓，地下2層'],
  ],
  gtmCode: ['NCN8CXN2', 'K5CDGVJ'], // 可放置多個
  recaptcha_site_key_v2: '6Lep-78UAAAAAMaZLtddpvpixEb8cqu7v7758gLz',
  recaptcha_site_key: '6Lck-L8UAAAAABxfvNA1NJuBrdl3iLFc3GkudC8s', // recaptcha v3
  recaptcha_user_token: '6Lck-L8UAAAAAIcvenwGDl8_Q1tKbrEYsKuriePa',
  order: {
    title: '眼鑑為真 敬邀體驗' ,
    subTitle: '若想了解建案資訊，歡迎填寫表單或來電洽詢，將由專人為您服務，謝謝！'
  },
}
