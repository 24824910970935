<template>
  <div class="section1">
     <img loading="lazy" src="./s1/bg.jpg" :alt="`${info.caseName}_img`" v-if="!isMobile" class="bg-img bg1" data-aos="fade-down" data-aos-delay="200">
     <img loading="lazy" src="./s1/bg.png" :alt="`${info.caseName}_img`" v-if="!isMobile" class="bg-img" data-aos="fade-down">
     <img loading="lazy" src="./s1/bg.jpg" :alt="`${info.caseName}_img`" v-if="isMobile" class="bg-img bg1" data-aos="fade-down" data-aos-delay="200">
     <img loading="lazy" src="./s1/bg-m.png" :alt="`${info.caseName}_img`" v-if="isMobile" class="bg-img bg2" data-aos="fade-down">

     <img loading="lazy" src="./s1/img.png" :alt="`${info.caseName}_img`" class="img" data-aos="fade-right">

	<img loading="lazy" src="./s1/t1.png" :alt="`${info.caseName}_img`" class="logo" data-aos="zoom-in">	
    <img loading="lazy" src="./s1/t2.png" :alt="`${info.caseName}_img`" class="t" data-aos="zoom-in">	



 <!--     <img src="./s1/o1.png" :alt="`${info.caseName}_img`" class="o1">
      <img src="./s1/o1.png" :alt="`${info.caseName}_img`" class="o12" v-if="!isMobile">
      <img src="./s1/o2.png" :alt="`${info.caseName}_img`" class="o2">
    <div class="img">
      <img src="./s1/1.png" :alt="`${info.caseName}_img`" class="img1">
      <img src="./s1/2.png" :alt="`${info.caseName}_img`" class="img2">
      <img src="./s1/3.png" :alt="`${info.caseName}_img`" class="img3">
      <img src="./s1/4.png" :alt="`${info.caseName}_img`" class="img4">
    </div>  -->
   <!-- <img src="./s1/logo宜娟要補svg動畫.png" :alt="`${info.caseName}_logo`" class="logo"> -->
    <!--  <svg version="1.1" id="圖層_1" xmlns="http://www.w3.org/2000/svg"  class="logo" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 752 689" style="enable-background:new 0 0 752 689;" xml:space="preserve">
    <title v-html="`${info.caseName}_logo`"></title>
      <rect x="111.6" y="-19.9" width="539.2" height="654.7" class="st0 an1"/>
      <polyline class="st1" points="101.3,-2 101.3,644 640.2,644 640.2,-2 "/>
<g class="an2">
	<rect x="180" y="419.8" class="st2" width="392.6" height="68.4"/>
	<path class="st3" d="M184.4,347.8h28.2c0.4-9.1,0.6-15.3,0.6-18.4h14c-0.4,7.5-0.6,13.6-0.6,18.4h28.5v12.5h-28.5
		c3.3,15.8,13.5,26,30.7,30.5c-2.3,2.6-8.1,9.6-9.3,11.8c-14.1-5.4-23.5-14.3-28.2-26.8c-3.5,10.8-13.2,20-29.2,27.7
		c-2.7-4.4-5.6-8.1-8.7-11.2c18.2-7.1,28.1-17.7,29.8-32.1h-27.3V347.8z M292.9,329.4h13v7.2h27.9v18.1h-13v-6.9h-31l14.3,3.1
		c-1,2.3-2.4,4.9-4,7.8h36.6v11.5h-11.5c-2.3,5.8-5.3,10.7-9,14.6c9.1,3.3,15.4,5.7,18.9,7.2l-5.9,11.8c-8.9-4.4-17.2-7.9-24.8-10.6
		c-8.1,3.9-20.3,7.5-36.6,10.6c-1.9-4.8-3.6-8.6-5.3-11.5c12.2-1.9,21.1-3.7,26.7-5.6c-1-0.4-4.4-1.9-5.6-2.2l-2.5,3.1l-12.4-6.9
		c3.1-3.7,5.8-7.3,8.1-10.6h-15.2v-11.5h22.6c2-3.3,2.9-6,5.3-10.9h-12.1v6.5h-13v-17.7h28.5V329.4z M292.9,370.2
		c-1.2,1.9-2.4,3.7-3.4,5.6c3.9,1.2,8.4,2.7,13.3,4.4c3.3-2.9,5.9-6.2,7.8-10H292.9z M343.8,389.5h31V378h-25.4v-11.8h25.4v-10.6
		h-14.9c-1,2.1-2.7,5.2-5,9.3c0.2-0.4,0-0.1-0.6,0.9c-1.7-1-5.6-3.1-11.8-6.2c5.6-8.3,9.5-17.4,11.8-27.4l13,2.2
		c-0.8,3.1-1.8,6.1-2.8,9h10.2v-14.3h13.3v14.3h24.5v12.1h-24.5v10.6h21.4V378h-21.4v11.5h27.6V401h-72V389.5z M427.8,349
		c4.8,3.7,9,7.5,12.7,11.2l-9,9c-3.9-4.4-8-8.4-12.1-12.1L427.8,349z M429,329.4c5.2,3.7,9.6,7.4,13.3,10.9l-8.7,9
		c-4.1-4.2-8.4-8.1-12.7-11.8L429,329.4z M429,371.8l11.8,4c-1.9,7.2-6.1,22.3-7.4,27.4l-12.1-3.7C424.4,390.7,427,381.5,429,371.8z
		 M443,332.8c18.4-0.4,34.4-1.1,48.1-2.2l1.2,10.3c-6.2,0.6-12.3,1-18.3,1.2v7.5h20.8v10.9H474v7.8h17.7v34.6h-12.4v-4H456v4h-12.4
		v-34.6h17.7v-7.8h-20.2v-10.9h20.2v-6.9c-3.1,0.3-13.8,0.5-17.1,0.6C444.3,340.1,443.7,337.4,443,332.8z M456,378.6v10.3h23.3
		v-10.3H456z M501.9,403.5v-72.2h70.4v72.2h-11.5V401h-47.1v2.5H501.9z M558.1,354.9h-9c0.2,0.8,0.6,1.6,1.2,2.2h9.3v7.2h-3.7
		c1.4,1.5,3.1,2.6,5,3.4v-27.1h-47.1v28.6c3.1-1.4,5.8-3.1,8.1-5H515v-7.2h13c0.4-0.6,0.7-1.3,0.9-2.2h-12.7v-7.2h4l-2.2-4l9.3-0.9
		c0.2,1.1,1.6,3.8,2.2,5h0.9c0.4-1.9,0.6-3.9,0.6-6.2h8.4c-0.1,1-0.3,5.2-0.6,6.2h5c0.6-0.7,2-4.2,2.5-5.3l9,0.6l-2.8,4.7h5.6V354.9
		z M549.4,381.1c0.4-1.2,0.7-2.6,0.9-4c2,0.7,6.9,2.3,8.4,2.5c-1.3,6.4-2.1,11.3-10.5,10.9c0,0-18.9,0-18.9,0
		c-10.4,0.5-8.2-7.7-8.4-14.6c-1.9,0.8-3.7,1.9-5.6,3.1l-1.6-3.7v17.1h47.1v-20.6c-0.6,0.7-1.6,3.7-2.2,5.6
		c-3.3-1.7-6.4-3.8-9.3-6.5c0.5,12.3-4.6,11-14.6,10.9c-0.4-1-1.2-3.7-1.6-5c1.8-0.3,7.2,0.9,7.4-0.9c0.2-0.4,0.3-0.7,0.3-0.9h-10.9
		v5.9c0,1.7,0.8,2.5,2.5,2.5H546C548,383.3,549.2,382.5,549.4,381.1z M547.2,368c-0.5-0.3-1.7-2.7-2.5-3.7h-11.5
		c-0.4,1-1.3,2.3-2.8,3.7H547.2z M541.3,357.1c-0.2-0.2-0.3-0.6-0.3-1.2c-0.2-0.4-0.3-0.7-0.3-0.9h-3.4c0,0.8-0.2,1.6-0.6,2.2H541.3
		z M359.8,532.7h23V543h-23V532.7z M480.7,505.1h44.2l-23,59.9h-13.5l18-49h-25.7V505.1z M528.1,505.1h44.2l-23,59.9h-13.5l18-49
		h-25.7V505.1z M207.3,553.8h17V565h-41.7l20.3-26c4.5-6.2,7.9-9.9,8.2-16.2c0.6-7.1-9.9-11-13.7-5.1c-1.2,1.9-1.9,4.9-2.2,8.9
		h-13.2c0.3-37.9,62.1-25.4,35.6,12.9C214.6,544.2,211.2,549.1,207.3,553.8z M240.8,522.2h-12.1c0.5-17.2,21.3-23.4,33.3-13.4
		c8.6,6.9,7.4,17.9-1.3,24.7c10.8,5.6,11.5,19.6,3.3,27.2c-12.5,10.5-33.9,4.6-35.7-13.3h12.3c1.7,8,8.5,11.2,13.8,6.3
		c3.2-3.1,2.7-9.3-0.4-12.2c-2.1-2.3-5.7-2.7-8.9-2.6v-10.1c8.1,0.9,14.2-6.3,8.7-13C248.6,510.9,241.2,514.4,240.8,522.2z
		 M298.1,565h-15.9l14.8-20.3c-20.6,4.8-32.5-21-17.5-34.5c14.1-12.5,38.6-5,38,15.8C317.5,540.4,305.3,554,298.1,565z M304.9,525.9
		c-0.1-12.4-18.3-12.6-18.4,0C286.5,538.2,304.7,537.9,304.9,525.9z M328.5,505.2h19.4V565h-13.1v-48.9h-8.6L328.5,505.2z
		 M400.3,505h29.6v11h-18l-0.7,7.1c12.5-0.4,21.1,8.5,21.2,20.1c-0.2,19.9-24.7,29.5-39.8,17.1l1.4-13.7
		c30.1,22.9,35.8-24.3,3.3-10.5L400.3,505z M443.9,505h29.6v11h-18l-0.7,7.1c12.5-0.4,21.1,8.5,21.2,20.1
		c-0.2,19.9-24.7,29.5-39.8,17.1l1.4-13.7c30.1,22.9,35.8-24.3,3.3-10.5L443.9,505z M204.7,431.3h15.4v47.5h-10.4V440h-6.8
		L204.7,431.3z M204.7,431.3h15.4v47.5h-10.4V440h-6.8L204.7,431.3z M236.4,444.9h-9.6c0.3-13.6,17.1-18.6,26.5-10.7
		c6.8,5.5,5.9,14.2-1.1,19.6c8.5,4.2,9.1,15.8,2.6,21.6c-9.7,8.4-27.2,3.4-28.4-10.6h9.8c1.3,6.3,6.7,8.9,10.9,5
		c4.1-4.3,0.4-12.1-5.6-11.7c0,0-1.8,0-1.8,0v-8c6.4,0.8,11.3-5.1,6.9-10.4C242.6,435.9,236.7,438.6,236.4,444.9z M263,453.2h11.6
		v5.7H263V453.2z M280.2,431.3h15.4v47.5h-10.4V440h-6.8L280.2,431.3z M299.5,431.3h35.1l-18.3,47.6h-10.7l14.3-38.9h-20.4V431.3z
		 M379.7,441.8l6.9,2.9c-1.7,3.8-3.4,7.2-5.2,10.2l-6.4-3.1v4.8h12.7v7.7h-12.7v15.4h-8.3v-15.4H355c0.1,1.4,0.2,5.4,0.4,6.7
		c-3.7,1.1-13.3,3.4-16.8,4.4l-1.7-7.9c1.9-0.4,3.8-0.8,5.6-1.2v-16.7h-4.8v-7.9h4.8v-11.7h7.9v11.7h4.2v7.9h-4.2v14.6
		c0.9-0.1,2.8-0.7,3.7-0.8v-6.7h12.5v-5l-6.4,3.3c-1.9-4-3.7-7.3-5.2-9.8l6.6-3.3c1.1,1.9,3.7,6.4,5,8.8v-10.6h-11v-7.5h30.3v7.5
		h-11v11.5C377,447.6,378.6,444.4,379.7,441.8z M393.1,467.7h50.5v4.8h-10.4l8.3,4l-5,4c-1-0.8-1.9-1.1-3.5-2.1
		c-13.2,0-24.7,0.2-34.4,0.6l-2.1-4.8h5.2c1.1-0.3,2.2-0.8,3.1-1.7h-11.7V467.7z M402.7,438.5h1.9v-0.8h-10.8v-5.2h10.8V430h7.9v2.5
		H424V430h7.9v2.5H443v5.2h-11.1v1.5h-2.3v2.3h8.6c-0.2,6,0.2,22.8,1.9,8.1c1.3,0.6,2.6,1,4,1.5c-0.4,2.1-0.7,5.4-1,6.3
		c-1.7,7-11.8,4.3-10.8-2.5l-2.5,4.4l-3.3-2.1c-0.7,1.1-2.2,2.8-4.4,5c-0.8-0.9-3.3-3.6-4.2-4.4c0,1.3,0,2.4,0,3.3
		c-7.5,0.1-15.2,0.3-22.9,0.6l-0.6-3.8c1.7,0,6.8-0.3,8.6-0.2v-1.3H396v-3.1h6.7v-1.5h6.3c-1-0.5-0.8-2.9-0.8-4h-2.7
		c-0.7,2.5-3.3,4.2-7.9,5.2c-0.6-1.8-1.3-3.1-2.1-4c2.5-0.3,4.1-0.7,4.8-1.3h-6v-3.3h8.6v-1h-7.1v-3.3h7.1V438.5z M397.3,462.3h42.1
		v4.2h-42.1V462.3z M423.1,438.5h0.8v-0.8h-11.5v1.5h-2.7v1h6.9v3.3h-6.9v1h8.3v3.3h-5.2c0.1,0.4-0.3,1.9,0.4,1.9
		c1,0.1,0.8-0.1,1.3-1.7c0.6,0,2.8,0.7,3.3,0.8c-0.3,2.5-1.5,3.7-3.8,3.5c-0.9,0-4,0.1-4.6-0.2v1h6.9v3.1h-6.9v1.3
		c2.5-0.1,5.1-0.2,7.9-0.2c0-0.1-0.1-0.2-0.2-0.2c1.5-1.3,2.7-2.4,3.5-3.5l-3.5-2.1l2.9-4.2c0.8,0.4,1.7,0.8,2.7,1.3
		c0-0.7,0.1-1.4,0.2-2.1h-4v-5.2h4V438.5z M412.3,474.2l13.6-0.2l-2.9-1.5h-7.1C415.2,473.1,413.4,473.8,412.3,474.2z M429.6,446.7
		c-0.1,1.8-0.4,3.5-0.8,5.2l3.1,1.7c0-1.8,0-4.1,0-6.9H429.6z M450.9,429.8h6.9v9.2h3.1v7.5h-3.1v4.8c1.5,1.3,2.9,2.3,4.2,3.1
		l-1.9,7.5c-0.4-0.5-1.7-1.7-2.3-1.9v19.8h-6.9v-17.5c-1.1,2.6-2.4,5.2-4,7.7c0-2.1-0.4-10.3-0.6-12.1c1.7-2.9,3.2-6.7,4.6-11.5
		h-3.5V439h3.5V429.8z M461.7,466.1l4.6-1.7h-4.2v-14.8h17.3v2.1l5.2-2.1c0.6,1.7,2.3,6.5,2.9,7.9v-10.8h-6.7v-7.9h6.7v-9h6.9v9h2.7
		v7.9h-2.7v25.4c0.6,7.5-5.6,7.3-11.5,7.1c-0.2-0.9-0.6-5.1-1.3-7.1c1.6,0,6.5,1.4,5.8-2.3c0,0,0-7.5,0-7.5l-4.4,1.9
		c-1.4-4.7-2.6-8.4-3.8-11.1v11.3h-4.2l4.6,1.3l-2.3,4.6c0.7-0.2,3-0.3,3.8-0.4c-0.1,1.8-0.2,4-0.2,6.7c-7.6,0.4-14.4,1-20.2,1.7
		l-1.5-6.9l4.8-0.2L461.7,466.1z M461.5,433.3h6v-3.5h6.7v3.5h5.8v6.3h-5.8v2.3h5.4v5.8h-17.7v-5.8h5.6v-2.3h-6V433.3z M466.1,470.9
		c0.9,0,3.8-0.3,4.8-0.2l2.3-6.3h-5.4l2.5,4.8L466.1,470.9z M473.2,458.6v-3.1h-4.6v3.1H473.2z M521,430.2h8.8v4.6h19v12.7h-5.6
		l1,5.2c-4.9,0.4-16,1.1-19.4,1.5v3.8l24.8-1.7l0.6,7.7l-10,0.6c2.4,0.8,5.1,1.7,8.1,2.5c-1.9,6.5-0.9,11.7-10.4,11.3
		c0,0-12.9,0-12.9,0c-8,0.1-9.4-5.3-9-12.1l-15,1l-0.6-7.9l15.6-0.8v-4l-10,0.6l-1.3-7.3h-3.1v-13.1H521V430.2z M510.1,447.3
		c6.3-0.3,18.5-1,30-1.9v-2.9h-30V447.3z M539.1,468.6c0.3-1.3,0.5-2.6,0.6-4l-15,1c0,2.9-0.7,6.2,3.5,5.6c0,0,7.1,0,7.1,0
		C537.6,471.4,538.8,470.5,539.1,468.6z"/>
	<path class="st2" d="M227.4,170.4c0,0.7,0,1,0,1.2v1.5c0,8.2,0.1,19.3,0.4,27c0,2.2-1.1,2.6-6.8,2.6c-3.9,0-4.3-0.3-4.3-2.2
		c0.4-11.5,0.4-14.6,0.4-30.1h-19v4.7c0,2.1-1.1,2.5-6.2,2.5c-3.8,0-4.2-0.3-4.2-2.2c0.4-14.7,0.4-15.6,0.4-23
		c0-7.5-0.1-10.6-0.4-16.3c5.4,2.4,7,3.2,12.1,6.1h17.4c-0.1-14.9-0.1-14.9-0.7-19.9c2.8,0.2,5.1,0.3,6.5,0.4c6.7,0.5,8.2,1,8.2,2.1
		c0,0.8-1,1.4-3.9,2.3v15.1h19.2c2.4-2.6,3.1-3.4,5.4-6c3.4,2.4,4.4,3.1,7.5,5.3c1,0.8,1.3,1.3,1.3,1.9c0,0.8-0.7,1.4-3.1,2.6
		c0,13.9,0.1,17.6,0.5,28.8c0,2.4-1.1,2.8-7,2.8c-3.1,0-3.5-0.3-3.5-1.9v-5.2H227.4z M217.1,160v-8.6c0-0.9,0-1.4,0-7.5h-19.1v24.7
		h19L217.1,160z M247.7,143.9h-20.4c0,1.2,0,9.5,0,24.7h20.4V143.9z M242,281.3c3.3-3.7,4.3-4.8,7.6-8.3c4.7,3.7,6.1,4.9,10.8,8.9
		c0.3,0.4,0.4,0.6,0.4,0.8c0,0.4-0.3,0.5-1,0.5H187c-3.7,0-6.2,0.2-8.1,0.4l-0.5-3.1c2.5,0.5,4.9,0.8,8.6,0.8h5.4v-40.1
		c0-3.4-0.1-5.2-0.5-8.8c2.8,0.3,5,0.6,6.4,0.7c5.8,0.6,7.7,1.2,7.7,2.4c0,0.8-1,1.4-3.5,2.2v43.6h14.1v-65.9h-25.6
		c-3.6,0-6,0.1-8.4,0.4l-0.5-3.1c3,0.6,5.2,0.8,9,0.8h49.2c2.9-3.4,3.8-4.5,6.8-7.8c4.6,3.4,6,4.6,10.5,8.2c0.4,0.4,0.6,0.7,0.6,1
		c0,0.4-0.3,0.5-1.1,0.5h-30.3v29.3h10.6c2.9-3.3,3.8-4.4,6.8-7.6c4.3,3.4,5.7,4.6,10.1,8.3c0.3,0.4,0.4,0.6,0.4,0.8
		c0,0.3-0.4,0.4-0.9,0.4h-27.1v34.7H242z M312.8,223.3c0,14.2,1.2,15.6,14,16v4h-47.9v-4c12.6-0.5,13.9-1.8,13.9-16v-75.4
		c0-13.9-1.2-15.4-13.3-15.9v-4h46.7v4c-12.2,0.5-13.4,2-13.4,15.9v31.6H364v-31.6c0-13.9-1.2-15.4-13.4-15.9v-4h46.9v4
		c-12.2,0.5-13.4,2-13.4,15.9v75.4c0,14.2,1.2,15.6,13.9,16v4H350v-4c12.8-0.5,14-1.8,14-16v-38.7h-51.2V223.3z M411.6,241.2
		c4.5-30.3,19.3-44.5,40.8-60.9c27-20.5,14.5-51.3-2.2-50.5c-16.6,0.8-15.7,15.7-16,24.4c-0.3,6-3.1,8.5-9.9,8.5
		c-7.4,0-10.9-2.6-10.9-8.2c0.3-18.3,19.9-29.3,38.4-28.7c44.9-1,49,44.4,13.3,60.6c-26.9,16.1-34.3,17.7-44.2,38.4h44.7
		c12.6,0,17.1-4,19.9-17.9l5.2,1.1l-5.4,33.3H411.6z M511.5,183.9c14.2-18.8,66.3-20.3,66.3,22.2c0,51.6-78.5,43.5-77.7,15.3
		c0.2-6.8,5.5-8.9,10.9-8.9c4.6,0,9.2,0.5,9.2,9.6c0,24.2,37.3,27.5,37.3-13.9c0-42.5-37.5-34.6-47.8-13.9l-5.1-1.2l5.1-64.7h63
		l-2.8,17.3h-55.5L511.5,183.9z"/>
	<path class="st3" d="M301.1,277.5h-4.4l-3.8-6h-2v6.1h-3.5V262h7.1c6.7-0.6,8.2,8.2,2.1,9.3L301.1,277.5z M293.8,264.8h-3v4.2h3
		C297.6,269.3,297.6,264.4,293.8,264.8z M314.5,277.6h-12.2V262h12v2.9h-8.5v3.4h7.9v2.9h-7.9v3.7h8.7V277.6z M329.8,266.6l-3.4,0.8
		c-1.5-4.5-7.4-2.6-7.1,2.5c-0.4,5.5,6.4,7.3,7.2,1.7l3.4,1.1c-1.7,8.4-15,6-14.3-2.7C315.1,261.3,327.4,258.8,329.8,266.6z
		 M335.2,277.6h-3.6V262h3.6V277.6z M350.6,264.9h-5v12.7H342v-12.7H337V262h13.7V264.9z M364.7,277.6h-12.2V262h12v2.9H356v3.4h7.9
		v2.9H356v3.7h8.7V277.6z M394.8,262l-3.4,15.6h-3.9c-0.3-1.3-2.7-10.6-2.7-11.7c0.1,1.1-2.6,10.5-2.8,11.7h-4.1l-3.4-15.6h3.6
		c0.1,0.6,2,11.7,1.9,11.8c0-0.1,0,0,0.1,0.5h0.1c0-1.1,2.4-11,2.7-12.3h3.7c0.2,1.2,2.9,11.3,2.8,12.3h0.1c0-1,1.7-11,1.9-12.3
		H394.8z M410.9,269.9c0.3,10.5-15.8,10.4-15.4,0C395.1,259.2,411.2,259.3,410.9,269.9z M407,269.9c0.3-6.7-8.1-6.6-7.9,0
		C398.9,276.5,407.4,276.6,407,269.9z M426.6,277.6H423c-0.6-1-6.7-10.4-7-11.2c0.1,1.7,0,9.3,0.1,11.2h-3.3V262h3.8
		c0.6,1.1,6.5,10.2,6.8,11.1c-0.1-1.4,0-9.4-0.1-11.1h3.3V277.6z M434,277.6h-5.1V262h4.9C445,260.9,445.4,279,434,277.6z
		 M433.7,264.9h-1.2v9.9C439.7,276.5,441,264.8,433.7,264.9z M456.3,277.6h-12.2V262h12v2.9h-8.5v3.4h7.9v2.9h-7.9v3.7h8.7V277.6z
		 M471.9,277.5h-4.4l-3.8-6h-2v6.1h-3.5V262h7.1c6.7-0.6,8.2,8.2,2.1,9.3L471.9,277.5z M464.6,264.8h-3v4.2h3
		C468.4,269.2,468.4,264.3,464.6,264.8z M484.1,264.9h-7.6v3.7h6.6v2.9h-6.6v6.1H473V262h11.1V264.9z M485.9,271V262h3.5
		c0.6,3.1-2.1,13.3,3.5,12.9c5.3,0.4,2.7-9.9,3.3-12.9h3.6v8.8C500.8,280,485.1,280.3,485.9,271z M513.5,277.6H502V262h3.5v12.8h8
		V277.6z M531.5,277.7H520v-15.6h3.5v12.8h8V277.7z M536.8,277.7h-3.6v-15.6h3.6V277.7z M550.2,265h-7.6v3.7h6.6v2.9h-6.6v6.1H539
		v-15.6h11.1V265z M564.2,277.7H552v-15.6h12v2.9h-8.5v3.4h7.9v2.9h-7.9v3.7h8.7V277.7z"/>
	<rect x="278.9" y="255.8" class="st4" width="293.7" height="27.3"/>
</g>
      <g>
	<defs>
		<path id="SVGID_1_" d="M641.2,645H100.3V-2h2v645h536.9V-2h2V645z M572.6,419.8H180v68.4h392.6V419.8z M247.7,170.4v5.2
			c0,1.7,0.4,1.9,3.5,1.9c6,0,7-0.4,7-2.8c-0.4-11.2-0.5-14.9-0.5-28.8c2.4-1.2,3.1-1.8,3.1-2.6c0-0.6-0.3-1.1-1.3-1.9
			c-3.1-2.2-4-2.9-7.5-5.3c-2.4,2.5-3.1,3.3-5.4,6h-19.2V127c2.9-0.9,3.9-1.5,3.9-2.3c0-1.1-1.6-1.6-8.2-2.1
			c-1.4-0.1-3.7-0.3-6.5-0.4c0.6,5,0.6,5,0.7,19.9h-17.4c-5.1-2.9-6.8-3.7-12.1-6.1c0.4,5.7,0.4,8.9,0.4,16.3c0,7.4,0,8.3-0.4,23
			c0,1.9,0.4,2.2,4.2,2.2c5.2,0,6.2-0.4,6.2-2.5v-4.7h19c-0.1,15.5-0.1,18.6-0.4,30.1c0,1.9,0.4,2.2,4.3,2.2c5.7,0,6.8-0.4,6.8-2.6
			c-0.3-7.6-0.4-18.8-0.4-27v-1.5c0-0.3,0-0.5,0-1.2H247.7z M217,168.6h-19v-24.7h19.1c0,6.1,0,6.6,0,7.5v8.6L217,168.6z
			 M247.7,168.6h-20.4c0-15.2,0-23.4,0-24.7h20.4V168.6z M226.8,281.3v-34.7h27.1c0.5,0,0.9-0.2,0.9-0.4c0-0.2-0.1-0.4-0.4-0.8
			c-4.4-3.7-5.8-4.8-10.1-8.3c-3,3.3-3.9,4.3-6.8,7.6h-10.6v-29.3h30.3c0.7,0,1.1-0.2,1.1-0.5c0-0.3-0.2-0.5-0.6-1
			c-4.5-3.6-5.9-4.7-10.5-8.2c-3,3.3-3.9,4.4-6.8,7.8h-49.2c-3.8,0-6-0.2-9-0.8l0.5,3.1c2.5-0.4,4.8-0.4,8.4-0.4h25.6v65.9h-14.1
			v-43.6c2.5-0.8,3.5-1.4,3.5-2.2c0-1.1-1.9-1.8-7.7-2.4c-1.4-0.1-3.6-0.4-6.4-0.7c0.4,3.6,0.5,5.3,0.5,8.8v40.1H187
			c-3.7,0-6.1-0.3-8.6-0.8l0.5,3.1c1.9-0.3,4.4-0.4,8.1-0.4h72.8c0.6,0,1-0.2,1-0.5c0-0.2-0.2-0.4-0.4-0.8
			c-4.7-3.9-6.2-5.2-10.8-8.9c-3.3,3.5-4.2,4.7-7.6,8.3H226.8z M312.8,184.6H364v38.7c0,14.2-1.2,15.6-14,16v4h47.9v-4
			c-12.6-0.5-13.9-1.8-13.9-16v-75.4c0-13.9,1.2-15.4,13.4-15.9v-4h-46.9v4c12.2,0.5,13.4,2,13.4,15.9v31.6h-51.2v-31.6
			c0-13.9,1.2-15.4,13.4-15.9v-4h-46.7v4c12,0.5,13.3,2,13.3,15.9v75.4c0,14.2-1.2,15.6-13.9,16v4h47.9v-4c-12.8-0.5-14-1.8-14-16
			V184.6z M485.3,241.2l5.4-33.3l-5.2-1.1c-2.8,13.9-7.2,17.9-19.9,17.9h-44.7c9.9-20.7,17.3-22.3,44.2-38.4
			c35.7-16.2,31.7-61.5-13.3-60.6c-18.5-0.6-38,10.4-38.4,28.7c0,5.5,3.5,8.2,10.9,8.2c6.8,0,9.6-2.5,9.9-8.5
			c0.3-8.6-0.6-23.6,16-24.4c16.7-0.8,29.1,30,2.2,50.5c-21.5,16.3-36.4,30.6-40.8,60.9H485.3z M514.6,145.5h55.5l2.8-17.3h-63
			l-5.1,64.7l5.1,1.2c10.3-20.7,47.8-28.6,47.8,13.9c0,41.3-37.3,38.1-37.3,13.9c0-9-4.7-9.6-9.2-9.6c-5.4,0-10.7,2.1-10.9,8.9
			c-0.9,28.3,77.7,36.4,77.7-15.3c0-42.5-52-41-66.3-22.2L514.6,145.5z M573,255.3H278.5v28.1H573V255.3z M279.4,256.2h292.8v26.4
			H279.4V256.2z"/>
	</defs>

	<clipPath id="SVGID_2_">
		<use xlink:href="#SVGID_1_" style="overflow:visible;"/>
	</clipPath>
	<g class="st6">
		<linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="185.2467" y1="193.203" x2="617.2767" y2="394.6619">
			<stop  offset="0" style="stop-color:#FFFFFF;stop-opacity:0"/>
			<stop  offset="0.5" style="stop-color:#FFFFFF"/>
			<stop  offset="1" style="stop-color:#FFFFFF;stop-opacity:0"/>
		</linearGradient>
		<rect x="61.2" y="-83" class="st5" width="624.5" height="727.8"/>
	</g>
</g>
    </svg>

      -->
    <!--
<div class="t1"><span>精彩生活 不只24小時</span></div>
<div class="t2"><span>華山特區｜雙捷運<b v-if="!isMobile">｜</b><br v-if="isMobile"><b><span>13．17．35</span>坪 風雅潮居</b></span></div>
-->
  </div>
</template>
<style lang="scss" scoped>
@import '~@/assets/style/function.scss';

.section1 {
  background-size: cover;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  min-height: size(900);
  max-height: size(1080);
  background: #1782c2;

  // background: linear-gradient(
  //   to bottom,
  //   rgba(0, 131, 190, 0) 13%,
  //   rgba(0, 131, 190, 0.66) 42%,
  //   #0083be 57%
  // );
  // background-size: cover;
}

.bg-img {
  width: 100%;
  height: 100%;
  position: absolute;
  display: block;
  bottom: 0;
  left: 0;
  object-fit: cover;
}
.bg1{
  
  height:auto;
}

.img{
  @include img_l_pc(515, 0,0);
  top: calc(50% + (170 - 540) * 100vw / 1920);
  left:-3vw;
}

.logo {
  font-size:size(20);
  @include img_l_pc(550, 230,460);
  top: calc(50% + (230 - 540) * 100vw / 1920);
filter: drop-shadow(0.2em 0.2em 0.3em rgba(0, 0, 0, 0.7));
	.st0{display:none;fill:none;stroke:#7CFF27;stroke-width:0.1;stroke-miterlimit:10;}
	.st1{fill:#F3E727;}
	.st2{fill:#158ACC;}
}
.t {
  @include img_l_pc(500, 220,1075);
  top: calc(50% + (230 - 540) * 100vw / 1920);
	.st0{fill:none;stroke:#FFFFFF;stroke-width:2;stroke-miterlimit:10;}
	.st1{fill:#F3E727;}
	.st2{fill:#1389CB;stroke:#F3E727;stroke-width:1.3;stroke-miterlimit:10;}
	.st3{fill:#FFFFFF;}
	.st4{fill:none;stroke:#F4E427;stroke-width:2.4;stroke-miterlimit:10;}
}


/* 平板尺寸 */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
}

@media screen and (max-width: 767px) {
  .section1 {
    width: 100vw;
    background-size: cover;
    margin: 0;
    height: calc(100vh - 63px);
    min-height: sizem(604);
    max-height: sizem(750);
    position: relative;
    z-index: 2;
    // overflow: hidden;
  }
.bg-img {
  height:auto;
}
.bg1{
  width: sizem(800);
  left: calc(50% - 400 * 100vw / 375);
  bottom: 10vw;
}
.bg2{
  top: calc(91% + (150 - 604 * .91) * 100vw / 375);
}
.img {
  @include img_l_m(180, 0,0);
  top: calc(70% + (335 - 604 * .70) * 100vw / 375);
  left:-12vw;
  object-fit:contain;
  height: calc(100% - (70% + (335 - 604 * .70) * 100vw / 375) );
  }

  .logo {
  font-size:sizem(15);
    @include img_c_m(270, 60);
  top: calc(50% + (60 - 302) * 100vw / 375);
  }
  .t {
    @include img_c_m(260, 196);
  top: calc(50% + (196 - 302) * 100vw / 375);
  }
  
}
</style>

<script>
// @ is an alias to /src
import info from '@/info'
import { isPC, isMobile, isTablet } from '@/utils'
// import slider from '@/mixins/slider.js'
// import Parallax from '@/components/Parallax.vue'
// import 'swiper/dist/css/swiper.css'
// import Parallax from '@/components/Parallax.vue'
// import { swiper, swiperSlide } from 'vue-awesome-swiper'

export default {
  name: 'section1',

  // mixins: [slider],
  components: {
    // swiper,
    // swiperSlide,
    // Parallax,
  },

  data() {
    return {
      info,
      isPC,
      isMobile,
      // swiperOption: {
      //   slidesPerView: isMobile ? 1.4 : 1,
      //   centeredSlides: true,
      //   spaceBetween: isMobile ? -10 : 30,
      //   slidesPerColumn: isMobile ? 1 : 1,
      //   effect: isMobile ? '' : 'fade',

      //   autoplay: {
      //     delay: 3000,
      //     disableOnInteraction: true,
      //   },
      //   loop: true,
      //   navigation: {
      //     nextEl: '.swiper-button-next',
      //     prevEl: '.swiper-button-prev',
      //   },
      //   pagination: {
      //     el: '.swiper-pagination',
      //     clickable: true,
      //   },
      // },

      // slideList: [
      //   {
      //     src: require('./s6/1.png'),
      //     title: 'INTERIOR SPACE',
      //     subtitle: '國際一線美學，名門傲氣優雅',
      //     desc: '代表作品∣頤海大院．台中帝寶．開璽吾界． SKY1',
      //   },
      //   {
      //     src: require('./s6/2.png'),
      //     title: 'ARCHITECTURE',
      //     subtitle: '動感琴鍵協奏，定義奢華高度',
      //     desc: '代表作品∣波爾多．江翠ONE．金城舞2',
      //   },
      //   {
      //     src: require('./s6/3.png'),
      //     title: 'LANDSCAPE',
      //     subtitle: '環境人文思考，都會繁花盛放',
      //     desc: '代表作品∣維多利亞酒店．碧波白',
      //   },
      // ],

      // imgIndex: 0,
      // isShowDialog: false,
    }
  },

  computed: {},

  methods: {
    // slideChanged(e) {
    //   const swiper = this.$refs.mySwiper.swiper
    //   if (swiper.isEnd) {
    //     this.slideIndex = 0
    //   } else if (swiper.isBeginning) {
    //     this.slideIndex = swiper.slides.length - 3
    //   } else {
    //     this.slideIndex = swiper.activeIndex - 1
    //   }
    // },
  },

  created() {},
  mounted() {},
}
</script>
